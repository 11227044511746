import React, { useState, useMemo } from 'react';
import './CustomWidget5.css';

// Dati statici delle card
const cardData = [
  {
    title: 'TD Synnex',
    subtitle: 'Prezzo IVA esclusa',
    items: [
      { label: 'Meta Quest 3', value: '€360' },
      { label: 'Meta Quest 3s', value: '€250' },
    ],
  },
  {
    title: 'ManageXR',
    subtitle: 'Costo licenza al mese IVA esclusa',
    items: [
      { label: 'Mensile', value: '€6,50' },
      { label: 'Annuale', value: '€5,75' },
    ],
  },
  {
    title: 'Accessori quest',
    subtitle: 'Prezzo IVA esclusa',
    items: [
      { label: 'Cinturino elite', value: '€20' },
      { label: 'Cavo mirroring 5 metri', value: '€18' },
    ],
  },
];

// Dati statici per la tabella "Simulazioni rilasciate"
const simulations = [
  { name: 'Antincendio ed evacuazione', releaseDate: '15-11-2023' },
  { name: 'Lavori in quota', releaseDate: '25-09-2024' },
  { name: 'Ponteggi', releaseDate: '12-09-2022' },
  { name: 'Videoterminali', releaseDate: '05-10-2024' },
  { name: 'Sversamento chimico', releaseDate: '15-03-2024' },
  { name: 'Officina meccanica', releaseDate: '11-01-2024' },
  { name: 'Generale 8108', releaseDate: '12-11-2022' },
  { name: 'Preposti', releaseDate: '08-04-2023' },
  { name: 'Cartellonistica', releaseDate: '23-11-2022' },
  { name: 'Carrelli elevatori', releaseDate: '24-02-2023' },
  { name: 'Spazi confinati', releaseDate: '12-11-2021' },
  { name: 'Cartellonistica', releaseDate: '28-11-2022' },
  { name: 'MMC', releaseDate: '12-07-2024' },
  { name: 'Addetti segnaletica stradale', releaseDate: '09-09-2023' },
];

// Dati statici per le prossime uscite
const upcomingReleases = [
  { name: 'Escape Room', period: 'Novembre' },
  { name: 'Rischio elettrico', period: 'Dicembre' },
  { name: '-', period: '-' },
  { name: '-', period: '-' },
];

// Componente Card
const InfoCard: React.FC<{ title: string; subtitle: string; items: { label: string; value: string }[] }> = ({
  title,
  subtitle,
  items,
}) => {
  return (
    <div className='card mb-4' style={{ backgroundColor: '#ffffff' }}>
      <div className='card-body'>
        <div className='text-dark fw-bold fs-2 mb-2 mt-5 custom-text-title margine-titoli-box'>{title}</div>
        <div className='text-muted fs-6 mb-4 custom-subtitle'>{subtitle}</div>
        {items.map((item, index) => (
          <div key={index} className='d-flex justify-content-between py-2'>
            <span className='text-muted custom-text'>{item.label}</span>
            <span className='text-muted fw-bold custom-text'>{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

// Componente per la tabella Simulazioni rilasciate con paginazione
const SimulationsTable: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(''); // Stato per il termine di ricerca
  const itemsPerPage = 3;

  // Funzione per dividere i dati in base alla pagina corrente
  const paginate = (data: any[], pageNumber: number, itemsPerPage: number) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  // Filtra i dati in base al termine di ricerca
  const filteredSimulations = useMemo(() => {
    return simulations.filter((simulation) =>
      simulation.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm]);

  const totalPages = Math.ceil(filteredSimulations.length / itemsPerPage);
  const currentData = paginate(filteredSimulations, currentPage, itemsPerPage);

  return (
    <div className='card' style={{ backgroundColor: '#ffffff' }}>
      {/* Header con titolo e barra di ricerca */}
      <div className='card-header border-0 pt-5 d-flex justify-content-between align-items-center'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1 custom-text-title'>Simulazioni rilasciate</span>
        </h3>
        {/* Campo di ricerca */}
        <div className='d-flex align-items-center'>
          <input
            type='text'
            className='form-control form-control-solid w-200px'
            placeholder='Cerca simulazioni...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {/* Corpo della tabella */}
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-3' style={{ backgroundColor: '#ffffff' }}>
            <tbody>
              {currentData.map((simulation, index) => (
                <tr key={index}>
                  <td className='custom-table-text text-muted'>{simulation.name}</td>
                  <td className='text-end'>
                    <span className='text-dark d-block fs-7 custom-table-text text-muted'>{simulation.releaseDate}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Componente di paginazione */}
        <Pagination
          totalItems={filteredSimulations.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

// Componente per le Prossime Uscite come tabella
const UpcomingReleases: React.FC = () => {
  return (
    <div className='card' style={{ backgroundColor: '#ffffff' }}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1 custom-text-title'>Prossime uscite</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-3' style={{ backgroundColor: '#ffffff' }}>
            <tbody>
              {upcomingReleases.map((release, index) => (
                <tr key={index}>
                  <td className='custom-table-text text-muted'>{release.name}</td>
                  <td className='custom-table-text text-muted text-end'>{release.period}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <div className="pagination-container d-flex justify-content-center align-items-center mt-3 no-margine2">
      <button
        className="btn btn-outline-secondary me-2"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      {[...Array(totalPages)].map((_, index) => (
        <button
          key={index}
          className={`btn btn-outline-secondary ${index + 1 === currentPage ? 'active' : ''} mx-1`}
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </button>
      ))}
      <button
        className="btn btn-outline-secondary ms-2"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;

// Componente principale CustomWidget5
const CustomWidget5: React.FC = () => {
  return (
    <div className="container-fluid" style={{ padding: '0px' }}>
      {/* Prima riga: Card informative */}
      <div className="row">
        {cardData.map((card, index) => (
          <div key={index} className="col-xl-4 col-lg-6">
            <InfoCard title={card.title} subtitle={card.subtitle} items={card.items} />
          </div>
        ))}
      </div>

      {/* Seconda riga: Tabella e prossime uscite */}
      <div className="row">
        <div className="col-xl-6 col-lg-12">
          <SimulationsTable />
        </div>
        <div className="col-xl-6 col-lg-12">
          <UpcomingReleases />
        </div>
      </div>
    </div>
  );
};

export { CustomWidget5 };

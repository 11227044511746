import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Formik, Form, FormikHelpers} from 'formik'
import {ICreateGoggle, createGoggleSchemas, inits} from './CreateGoggleChecker'
import {createGoggleInStock} from '../../../../api/gogglesApi'
import {Modal} from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import Select from 'react-select'
import axios from '../../../../config/axiosConfig'

interface IOption {
  value: number
  label: string
}

export interface ISeller {
  ID_Venditore: number
  Nome: string
  Nazione: string
}

export interface IGoggle {
  ID_Visore: number
  Modello: string
  Prezzo: number
  COD_Produttore: number
}

export interface ICategory {
  ID_Categoria: number
  Nome: string
  Descrizione: number
}

const InserisciVisoreInStock: React.FC = ({ }) => {
  const [initValues] = useState<ICreateGoggle>(inits)
  const [showModal, setShowModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('');

  // Stati per i menù a tendina
  const [venditori, setVenditori] = useState<ISeller[]>([]);
  const [selectedVenditori, setSelectedVenditori] = useState<IOption | null>(null);
  const [visori, setVisori] = useState<IGoggle[]>([]);
  const [selectedVisori, setSelectedVisori] = useState<IOption | null>(null);
  const [categorie, setCategorie] = useState<ICategory[]>([]);
  const [selectedCategorie, setSelectedCategorie] = useState<IOption | null>(null);


  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const sellers = await axios.post<ISeller[]>('/api/sellers/all');
        const orderedSellers = sellers.data.sort((a, b) => a.Nome.localeCompare(b.Nome));
        setVenditori(sellers.data);

        const goggles = await axios.post<IGoggle[]>('/api/goggles/all');
        const orderedGoggles = goggles.data.sort((a, b) => a.Modello.localeCompare(b.Modello));
        setVisori(goggles.data);

        const categories = await axios.post<ICategory[]>('/api/category/list');
        const orderedCategories = categories.data.sort((a, b) => a.Nome.localeCompare(b.Nome));
        setCategorie(categories.data);
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])


  const submitForm = async (values: ICreateGoggle, actions: FormikHelpers<ICreateGoggle>) => {
    try {
      const response = await createGoggleInStock(values)
      if (response.status === 201) {
        setModalMessage(response.data);
      }
      setShowModal(true);
	  
	  // Reset dei campi del form
      actions.resetForm();

      // Reset dei menù a tendina
      setSelectedVenditori(null);
      setSelectedVisori(null);
      setSelectedCategorie(null);
	  
      } catch (error: any) {
        console.error('Errore nella chiamata API:', error);
        console.error('Errore nella chiamata API 2:', error.data);
        if (error.response) {
          // La richiesta è stata effettuata e il server ha risposto con uno stato fuori dal range 2xx
          if (error.response.status === 400) {
            setModalMessage(error.response.data); //esiste già un visore con questi seriali
          }
          else if (error.response.status === 419) {
            setModalMessage(error.response.data); //almeno uno dei due seriali deve essere inserito
          }
        } else if (error.request) {
          // La richiesta è stata effettuata, ma non c'è stata alcuna risposta
          setModalMessage(error.response.data);
        } else {
          // Qualcosa è andato storto nella creazione della richiesta
          setModalMessage(error.response.data);
        }
        setShowModal(true);
      }
      actions.resetForm()
  }

  const closeModal = () => setShowModal(false)

  return (
    <div className='d-flex flex-column flex-center bg-body rounded'>
      <Formik initialValues={initValues} validationSchema={createGoggleSchemas} onSubmit={submitForm}>
        {({ setFieldValue }) => (
          <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>

            <div className='pb-10 pb-lg-15'>
              <h2 className='fw-bolder text-dark'>Visore</h2>
              <div className='text-gray-400 fw-bold fs-6'>Inserisci i dati</div>
            </div>

            {/* Inizio data acquisto visore */}
            <div className='fv-row mb-10'>
              <label htmlFor='Data_acquisto' className='form-label'>
                Data acquisto visore
              </label>
              <Field
                type='date'
                id='Data_acquisto'
                name='Data_acquisto'
                className='form-control form-control-lg form-control-solid'
              />
              
                <div className='text-danger mt-2'>
                  <ErrorMessage name='Data_acquisto' />
                </div>
              
            </div>
            {/*Fine data acquisto visore*/}

            {/* Seriale scatola */}
            <div className='fv-row mb-10'>
              <label htmlFor='Seriale_scatola' className='form-label'>
                Seriale scatola
              </label>
              <Field
                type='text'
                id='Seriale_scatola'
                name='Seriale_scatola'
                className='form-control form-control-lg form-control-solid'
              />
        
                <div className='text-danger mt-2'>
                  <ErrorMessage name='Seriale_scatola' />
                </div>
              
            </div>

            {/* Seriale visore */}
            <div className='fv-row mb-10'>
              <label htmlFor='Seriale_visore' className='form-label'>
                Seriale visore
              </label>
              <Field
                type='text'
                id='Seriale_visore'
                name='Seriale_visore'
                className='form-control form-control-lg form-control-solid'
              />
        
                <div className='text-danger mt-2'>
                  <ErrorMessage name='Seriale_visore' />
                </div>
              
            </div>
            
            {/* Venditore */}
            <div className='fv-row mb-10'>
              <label className='form-label required'>
                Venditore
              </label>
              <Select
                className='form-select-solid form-select-lg fw-bold'
                value={selectedVenditori}
                placeholder="Scegli venditore"
                options={venditori.map(venditore => ({ value: venditore.ID_Venditore, label: venditore.Nome }))}
                onChange={(selectedOption: IOption | null) => {
                  if (selectedOption) {
                    setSelectedVenditori(selectedOption);
                    setFieldValue('COD_Venditore', selectedOption.value);
                  } else {
                    setSelectedVenditori(null);
                    setFieldValue('COD_Venditore', null);
                  }
                }}
              />

                <div className='text-danger mt-2'>
                  <ErrorMessage name='COD_Venditore' />
                </div>
            </div>
            
            {/* Modello */}
            <div className='fv-row mb-10'>
              <label className='form-label required'>
                Modello
              </label>
              <Select
                className='form-select-solid form-select-lg fw-bold'
                value={selectedVisori}
                placeholder="Scegli visore"
                options={visori.map(visori => ({ value: visori.ID_Visore, label: visori.Modello }))}
                onChange={(selectedOption: IOption | null) => {
                  if (selectedOption) {
                    setSelectedVisori(selectedOption);
                    setFieldValue('COD_Visore', selectedOption.value);
                  } else {
                    setSelectedVisori(null);
                    setFieldValue('COD_Modello', null);
                  }
                }}
              />

                <div className='text-danger mt-2'>
                  <ErrorMessage name='COD_Visore' />
                </div>
            </div>

            {/* Categoria */}
            <div className='fv-row mb-10'>
              <label className='form-label required'>
                Categoria
              </label>
              <Select
                className='form-select-solid form-select-lg fw-bold'
                value={selectedCategorie}
                placeholder="Scegli categoria"
                options={categorie.map(categorie => ({ value: categorie.ID_Categoria, label: categorie.Nome }))}
                onChange={(selectedOption: IOption | null) => {
                  if (selectedOption) {
                    setSelectedCategorie(selectedOption);
                    setFieldValue('COD_Categoria', selectedOption.value);
                  } else {
                    setSelectedCategorie(null);
                    setFieldValue('COD_Categoria', null);
                  }
                }}
              />

                <div className='text-danger mt-2'>
                  <ErrorMessage name='COD_Categoria' />
                </div>
            </div>
            
            <div className='d-flex flex-stack pt-10'>
              <button type='submit' className='btn btn-lg btn-primary me-3'>
                Invia
                <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0' />
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Visore inserito nel sistema</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={closeModal}>
            Chiudi
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export { InserisciVisoreInStock }
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';

const SidebarLogo = () => {
  const { config } = useLayout();
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : '';
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : '';
  const appSidebarDefaultMinimizeDefault =
    config.app?.sidebar?.default?.minimize?.desktop?.default;

  return (
    <div
      className="app-sidebar-logo px-6 d-flex justify-content-center"
      id="kt_app_sidebar_logo"
      style={{ borderBottom: '0px', marginTop: '20px' }} // Aggiungi margine qui
    >
      <Link to="/dashboard">
        {config.layoutType === 'dark-sidebar' ? (
          <img
            alt="Logo"
            src={toAbsoluteUrl('/media/logos/default-dark.svg')}
            className="h-80px app-sidebar-logo-default"
          />
        ) : (
          <>
            <img
              alt="Logo"
              src={toAbsoluteUrl('/media/logos/default.svg')}
              className="h-80px app-sidebar-logo-default theme-light-show"
            />
            <img
              alt="Logo"
              src={toAbsoluteUrl('/media/logos/default-dark.svg')}
              className="h-80px app-sidebar-logo-default theme-dark-show"
            />
          </>
        )}
      </Link>

      {/* Toggle button code if needed */}
    </div>
  );
};

export { SidebarLogo };